import cn from 'classnames';
import SearchFormSegments from './components/SearchForm/Segments/Segments.css';
import SearchFormSegment from './components/SearchForm/Segment/Segment.css';
import SearchFormControls from './components/SearchForm/Controls/Controls.css';
import SearchFormMultiCity from './components/SearchForm/MultiCity/MultiCity.css';
import SearchFormPassengers from './components/SearchForm/Passengers/Passengers.css';
import SearchFormCurrency from './components/SearchForm/Currency/Currency.css';
import SearchFormStartSearch from './components/SearchForm/StartSearch/StartSearch.css';
import SearchFormDatepicker from './components/SearchForm/Datepicker/Datepicker.css';
import SearchFormLocation from './components/SearchForm/Location/Location.css';
import SearchFormBottom from './components/SearchForm/Bottom/Bottom.css';
import SearchFormActions from './components/SearchForm/Actions/Actions.css';
import SearchFormDatepickerMonthHeader from './components/SearchFormDatepicker/MonthHeader/MonthHeader.css';
import SearchFormDatepickerWeeklyHeader from './components/SearchFormDatepicker/WeeklyHeader/WeeklyHeader.css';
import SearchFormDatepickerDay from './components/SearchFormDatepicker/Day/Day.css';
import SearchFormDatePickerMonthsWrapper from './components/SearchFormDatepicker/MonthsWrapper/MonthsWrapper.css';
import Results from './components/Results/Results.css';
import StepbarStep from './components/Stepbar/Step/Step.css';
import LocationAutocomplete from './components/LocationAutocomplete/LocationAutocomplete.css';
import DirectionsDialog from './components/LocationAutocomplete/DirectionsDialog/DirectionsDialog.css';
import Passengers from './components/Passengers/Passengers.css';
import Stepbar from './components/Stepbar/Stepbar.css';
import MobileStepbar from './components/MobileStepbar/MobileStepbar.css';
import Button from './components/Button/Button.css';
import Toolbar from './components/Toolbar/Toolbar.css';
import Contacts from './components/Contacts/Contacts.css';
import Link from './components/Link/Link.css';
import Amenities from './components/Amenities/Amenities.css';
import PassengerFieldSwitch from './components/Passenger/Field/Switch/Switch.css';
import DesktopSummaryFlightHeader from './components/DesktopSummaryFlight/Header/Header.css';
import DesktopSummaryFlight from './components/DesktopSummaryFlight/DesktopSummaryFlight.css';
import CompareFares from './components/CompareFares/CompareFares.css';
import CheckoutFormPassengersToolbar from './components/Checkout/Form/PassengersToolbar/PassengersToolbar.css';
import CheckoutConsents from './components/Checkout/Consents/Consents.css';
import OrderCardOrderBooking from './components/OrderCard/OrderBooking/OrderBooking.css';
import OrderCardOrderChange from './components/OrderCard/OrderChange/OrderChange.css';
import OrderCardOrderPassengers from './components/OrderCard/OrderPassengers/OrderPassengers.css';
import OrderCardControls from './components/OrderCard/OrderCardControls/OrderCardControls.css';
import DesktopFlightModalHeader from './components/DesktopFlight/DesktopFlightModalHeader/DesktopFlightModalHeader.css';
import DesktopFlightSelectedFareGroup from './components/DesktopFlight/SelectedFareGroup/SelectedFareGroup.css';
import FlightCard from './components/FlightCard/FlightCard.css';
import AircraftPopup from './components/AircraftPopup/AircraftPopup.css';
import MobileFlight from './components/MobileFlight/Flight.css';
import Baggage from './components/Baggage/Baggage.css';
import BaggageList from './components/BaggageList/BaggageList.css';
import CheckoutAdditionalService from './components/CheckoutAdditionalService/CheckoutAdditionalService.css';
import ResultsCalendar from './components/Results/Calendar/Calendar.css';
import ResultsSortings from './components/Results/Sortings/Sortings.css';
import ResultsFlightsList from './components/Results/FlightsList/FlightsList.css';
import ResultsMobilePriceGraph from './components/Results/MobilePriceGraph/MobilePriceGraph.css';
import WeekCalendar from './components/WeekCalendar/WeekCalendar.css';
import WeekCalendarSlider from './components/WeekCalendar/Slider/Slider.css';
import DesktopFlightRoute from './components/DesktopFlightRoute/DesktopFlightRoute.css';
import DesktopFlightRoutePrice from './components/DesktopFlightRoute/FlightPrice/FlightPrice.css';
import DesktopFlightRoutePoint from './components/DesktopFlightRoute/Point/Point.css';
import DesktopFlightRouteArrow from './components/DesktopFlightRoute/RouteArrow/RouteArrow.css';
import DesktopFlightRouteSegment from './components/DesktopFlightRoute/Segment/Segment.css';
import DesktopFlightRouteStopsInfo from './components/DesktopFlightRoute/StopsInfo/StopsInfo.css';
import DesktopFlightModalContent from './components/DesktopFlight/ModalContent/ModalContent.css';
import DesktopFlightInfoSegment from './components/DesktopFlight/FlightInfoSegment/FlightInfoSegment.css';
import DesktopFlightLocation from './components/DesktopFlight/Location/Location.css';
import FareGroup from './components/FareGroup/FareGroup.css';
import FareGroupGrid from './components/FareGroup/FareGroupGrid/FareGroupGrid.css';
import FareGroupIcon from './components/FareGroup/FareGroupIcon/FareGroupIcon.css';
import TransferInfo from './components/TransferInfo/TransferInfo.css';
import Modal from './components/Modal/Modal.css';
import FareConditions from './components/FareConditions/FareConditions.css';
import FareLock from './components/FareLock/FareLock.css';
import FareLockDescription from './components/FareLock/DescriptionBlock/DescriptionBlock.css';
import FareLockSwitch from './components/FareLock/SwitchBlock/SwitchBlock.css';
import MobileToolbar from './components/MobileToolbar/MobileToolbar.css';
import PriceGraphLegends from './components/PriceGraph/Legends/Legends.css';
import Switch from './components/Switch/Switch.css';
import AdditionalServiceBanner from './components/AdditionalServiceBanner/AdditionalServiceBanner.css';
import AdditionalBaggage from './components/AdditionalBaggage/AdditionalBaggage.css';
import BaggageTotalPriceBlock from './components/BaggageTotalPriceBlock/BaggageTotalPriceBlock.css';
import BaggageCard from './components/BaggageCard/BaggageCard.css';
import UpgradeBaggageDialog from './components/UpgradeBaggageDialog/UpgradeBaggageDialog.css';
import Counter from './components/Counter/Counter.css';
import Airplane from './components/SeatMap/Airplane/Airplane.css';
import Passenger from './components/SeatMap/Passengers/Passenger.css';
import SeatsInfo from './components/SeatMap/SeatsInfo/SeatsInfo.css';
import Seat from './components/SeatMap/Seat/Seat.css';
import SeatTooltip from './components/SeatMap/SeatTooltip/SeatTooltip.css';
import SegmentTabs from './components/SegmentTabs/SegmentTabs.css';
import CheckinInitial from './components/Checkin/Initial/Initial.css';
import CheckinPassengers from './components/Checkin/Passengers/Passengers.css';
import RetrieveBooking from './components/RetrieveBooking/RetrieveBooking.css';
import CheckinRules from './components/CheckinRules/CheckinRules.css';
import Segment from './components/Segment/Segment.css';
import MobileSegment from './components/MobileSegment/MobileSegment.css';
import SelectedPassenger from './components/SelectedPassenger/SelectedPassenger.css';
import AddPassenger from './components/AddPassenger/AddPassenger.css';
import RegisteredPassenger from './components/RegisteredPassenger/RegisteredPassenger.css';
import RegisteredPassengerService from './components/RegisteredPassenger/Service/Service.css';
import ResendTicketsForm from './components/ResendTicketsForm/ResendTicketsForm.css';
import StatusMessage from './components/StatusMessage/StatusMessage.css';
import MealService from './components/MealService/MealService.css';
import CheckoutThanksBanner from './components/Checkout/ThanksBanner/ThanksBanner.css';
import Summary from './components/QuickSearchForm/Summary/Summary.css';
import Controls from './components/SeatMap/Controls/Controls.css';
import PopupWrapper from './components/PassengerForm/PopupWrapper/PopupWrapper.css';
import PaymentForm from './components/PaymentForm/components/PaymentForm.css';
var theme = {
    AdditionalServiceBanner: {
        AdditionalServiceBannerStyles: {
            baggageBanner: AdditionalServiceBanner.baggageBanner,
            text: AdditionalServiceBanner.text,
            disclaimer: AdditionalServiceBanner.disclaimer,
            disclaimer__icon: AdditionalServiceBanner.disclaimer__icon,
            image: AdditionalServiceBanner.image
        }
    },
    AdditionalBaggage: {
        PromoMessage: {
            root: AdditionalBaggage.promoMessage_root
        }
    },
    BaggageTotalPriceBlock: {
        BaggageTotalPriceBlock: {
            price: BaggageTotalPriceBlock.price,
            control: BaggageTotalPriceBlock.control,
            control__edit: BaggageTotalPriceBlock.controlEdit
        }
    },
    BaggageCard: {
        BaggageCard: {
            icon: BaggageCard.icon,
            button: BaggageTotalPriceBlock.control,
            button_edit: BaggageTotalPriceBlock.controlEdit
        }
    },
    UpgradeBaggageDialog: {
        HeaderEquipmentItem: {
            wrapper: UpgradeBaggageDialog.headerEquipmentItem__wrapper,
            active: UpgradeBaggageDialog.headerEquipmentItem__active
        },
        BaggagePassenger: {
            name: UpgradeBaggageDialog.baggagePassenger__name
        },
        BaggageFooter: {
            root: UpgradeBaggageDialog.baggageFooter__root,
            confirm_btn: UpgradeBaggageDialog.baggageFooter__confirmBtn,
            cancel_btn: UpgradeBaggageDialog.baggageFooter__cancelBtn,
            clear: UpgradeBaggageDialog.baggageFooter__clear,
            price_wrapper: UpgradeBaggageDialog.baggageFooter__priceWrapper
        },
        BaggageDialogHeader: {
            category_title: UpgradeBaggageDialog.baggageDialogHeader__categoryTitle,
            price_label: UpgradeBaggageDialog.baggageDialogHeader__priceLabel,
            perFlight: UpgradeBaggageDialog.baggageDialogHeader__perFlight
        },
        BaggageSegment: {
            root: UpgradeBaggageDialog.baggageSegment__root
        },
        MobileBaggageSelector: {
            footer: UpgradeBaggageDialog.mobileBaggageSelector__root
        }
    },
    Counter: {
        Counter: {
            button: Counter.button,
            button_disabled: Counter.buttonDisabled
        }
    },
    Button: {
        Button: Button
    },
    Contacts: {
        ContactsStyles: {
            description__header: Contacts.description__header,
            toggle: Contacts.toggle,
            footer__inner: Contacts.footer__inner
        }
    },
    Link: {
        Link: {
            link: Link.link
        }
    },
    Passenger: {
        Switch: {
            element_active: PassengerFieldSwitch.element_active
        }
    },
    DesktopSummaryFlight: {
        SummaryFlight: {
            features: DesktopSummaryFlight.features
        },
        Header: {
            header__cell: DesktopSummaryFlightHeader.header__cell,
            details: DesktopSummaryFlightHeader.details
        }
    },
    Checkout: {
        Consents: {
            checkbox_checked: CheckoutConsents.checkbox_checked,
            checkbox: CheckoutConsents.checkbox,
            consents__text: CheckoutConsents.consents__text
        },
        PassengersToolbar: {
            checkbox_checked: CheckoutFormPassengersToolbar.checkbox_checked,
            checkbox: CheckoutFormPassengersToolbar.checkbox,
            terms: CheckoutFormPassengersToolbar.terms
        },
        ThanksBanner: {
            title: CheckoutThanksBanner.title
        }
    },
    OrderCard: {
        OrderBooking: {
            download: OrderCardOrderBooking.download
        },
        OrderChange: {
            button: OrderCardOrderChange.button
        },
        OrderPassengers: {
            control: OrderCardOrderPassengers.control
        },
        OrderCardControls: {
            root: OrderCardControls.root,
            button: OrderCardControls.button,
            button_checkin: OrderCardControls.button_checkin
        }
    },
    AircraftPopup: {
        AircraftPopup: {
            button: AircraftPopup.button,
            close: AircraftPopup.close
        }
    },
    MobileFlight: {
        FlightStyles: {
            subsidies: MobileFlight.subsidies,
            selected: MobileFlight.selected,
            price__close: MobileFlight.price__close,
            price: MobileFlight.price,
            selectedFare: MobileFlight.selectedFare
        }
    },
    Baggage: {
        BaggageIcon: {
            wrapper: Baggage.baggageIcon_wrapper
        },
        Baggage: {
            icon: Baggage.icon,
            name: Baggage.name,
            size: Baggage.size
        }
    },
    BaggageList: {
        BaggageList: {
            root: BaggageList.root,
            add: BaggageList.add
        },
        BaggageDirection: {
            root: BaggageList.baggageDirection__root
        },
        BaggageCount: {
            root: BaggageList.baggageCount__root
        }
    },
    CheckoutAdditionalService: {
        CheckoutAdditionalService: {
            service_active: CheckoutAdditionalService.service_active,
            content: CheckoutAdditionalService.content,
            add: CheckoutAdditionalService.add
        }
    },
    SearchForm: {
        Segments: {
            segments: SearchFormSegments.segments,
            segments_multiCity: cn(SearchFormSegments.segments_multiCity, SearchFormSegment.segments_multiCity)
        },
        Segment: {
            wrapper: SearchFormSegment.wrapper,
            segment: SearchFormSegment.segment,
            withMultiCity: SearchFormSegment.withMultiCity,
            cell: SearchFormSegment.cell,
            cell_date: SearchFormSegment.cell_date,
            cell_location: SearchFormSegment.cell_location,
            input: SearchFormSegment.input,
            input_text: SearchFormSegment.input_text,
            input_focused: SearchFormSegment.input_focused,
            remover: SearchFormSegment.remover,
            datepicker_value: SearchFormSegment.datepicker_value,
            alternative: SearchFormSegment.alternative
        },
        Controls: {
            controls: SearchFormControls.controls,
            controls_multiCity: cn(SearchFormControls.controls_multiCity, SearchFormCurrency.controls_multiCity)
        },
        MultiCity: {
            multiCity: SearchFormMultiCity.multiCity,
            caption: SearchFormMultiCity.caption
        },
        Passengers: {
            passengers: SearchFormPassengers.passengers,
            passengers_isMultiCity: SearchFormPassengers.passengers_isMultiCity,
            menu: SearchFormPassengers.menu,
            selector: SearchFormPassengers.selector,
            title: SearchFormPassengers.title,
            alternative: SearchFormPassengers.alternative
        },
        Currency: {
            currency: SearchFormCurrency.currency,
            selector: SearchFormCurrency.selector,
            arrowIcon: SearchFormCurrency.arrowIcon,
            item: SearchFormCurrency.item,
            dropdown: SearchFormCurrency.dropdown
        },
        StartSearch: {
            startSearch: SearchFormStartSearch.startSearch,
            startSearch_iconMode: SearchFormStartSearch.startSearch_iconMode,
            startSearch_multiCity: SearchFormStartSearch.startSearch_multiCity
        },
        Datepicker: {
            dates: SearchFormDatepicker.dates,
            dates_focused: SearchFormDatepicker.dates_focused,
            dates__to: SearchFormDatepicker.dates__to,
            dates__back: SearchFormDatepicker.dates__back,
            dates__to_focused: SearchFormDatepicker.dates__to_focused,
            dates__back_focused: SearchFormDatepicker.dates__back_focused,
            dates__to_text: SearchFormDatepicker.dates__to_text,
            placeholder: SearchFormDatepicker.placeholder,
            date__inputWrapper: SearchFormDatepicker.date__inputWrapper,
            dow: SearchFormDatepicker.dow,
            highlighted_minPrice: SearchFormDatepicker.highlighted_minPrice,
            alternative: SearchFormDatepicker.alternative,
            alternative_dow: SearchFormDatepicker.alternative_dow
        },
        Location: {
            switcher: SearchFormLocation.switcher,
            switcher_alternative: SearchFormLocation.switcher_alternative
        },
        Bottom: {
            controls: SearchFormBottom.controls
        },
        Actions: {
            actions: SearchFormActions.actions,
            addSegment: SearchFormActions.addSegment,
            removeSegment: SearchFormActions.removeSegment
        }
    },
    SearchFormDatepicker: {
        MonthHeaderStyles: {
            monthHeader: SearchFormDatepickerMonthHeader.monthHeader,
            monthHeader__select_month: SearchFormDatepickerMonthHeader.monthHeader__select_month,
            monthHeader__select_year: SearchFormDatepickerMonthHeader.monthHeader__select_year
        },
        WeeklyHeaderStyles: {
            dayOfWeekName_withPrices: SearchFormDatepickerWeeklyHeader.dayOfWeekName_withPrices
        },
        DayStyles: {
            day_withPrice: SearchFormDatepickerDay.day_withPrice,
            day_selected: SearchFormDatepickerDay.day_selected,
            day__price: SearchFormDatepickerDay.day__price,
            day_today: SearchFormDatepickerDay.day_today,
            day_inPeriod: SearchFormDatepickerDay.day_inPeriod,
            day_hasPrice: SearchFormDatepickerDay.day_hasPrice,
            day_weekend: SearchFormDatepickerDay.day_weekend,
            day_startHover_withprice: SearchFormDatepickerDay.day_startHover_withprice,
            day_startHover_withPrice: SearchFormDatepickerDay.day_startHover_withPrice,
            day_endHover_withPrice: SearchFormDatepickerDay.day_endHover_withPrice,
            day_highlighted: SearchFormDatepickerDay.day_highlighted
        },
        MonthsWrapperStyles: {
            wrapper: SearchFormDatePickerMonthsWrapper.wrapper,
            monthsList: SearchFormDatePickerMonthsWrapper.monthsList,
            monthsList_withPrice: SearchFormDatePickerMonthsWrapper.monthsList_withPrice
        }
    },
    Stepbar: {
        Stepbar: {
            stepbar: Stepbar.stepbar
        },
        Step: {
            step: StepbarStep.step,
            step_disabled: StepbarStep.step_disabled
        }
    },
    LocationAutocomplete: {
        Group: {
            group__label: LocationAutocomplete.group__label
        },
        Option: {
            option: LocationAutocomplete.option,
            info: LocationAutocomplete.option__info,
            label: LocationAutocomplete.option__label,
            code: LocationAutocomplete.option__code
        },
        Dropdown: {
            allDirections: LocationAutocomplete.allDirections,
            allDirections__button: LocationAutocomplete.allDirections__button,
            options: LocationAutocomplete.dropdown__options
        },
        DirectionsDialog: {
            paper: DirectionsDialog.paper,
            dialog: DirectionsDialog.dialog,
            cities: DirectionsDialog.cities,
            label: DirectionsDialog.label,
            countries: DirectionsDialog.countries,
            countries__list: DirectionsDialog.countries__list,
            country: DirectionsDialog.country,
            country_active: DirectionsDialog.country_active,
            city: DirectionsDialog.city,
            iata: DirectionsDialog.iata
        }
    },
    Passengers: {
        PassengerStyles: {
            decrease: Passengers.decrease,
            increase: Passengers.increase
        }
    },
    Inner: {
        Inner: {
            inner: Results.inner__inner
        }
    },
    Results: {
        Results: {
            results: Results.results,
            header: Results.header,
            header__stepbar: Results.header__stepbar,
            inner: Results.inner,
            toolbar__backButton: Results.toolbar__backButton,
            share: Button.button_share
        },
        Calendar: {
            header: ResultsCalendar.header,
            legNumber: ResultsCalendar.legNumber,
            title__text: ResultsCalendar.title__text,
            title__cities: ResultsCalendar.title__cities,
            diagram: ResultsCalendar.diagram
        },
        Sortings: {
            title: ResultsSortings.title,
            sortBy: ResultsSortings.sortBy,
            currentSorting: ResultsSortings.currentSorting,
            flightType: ResultsSortings.flightType,
            flightType__active: ResultsSortings.flightType__active,
            listItem: ResultsSortings.listItem,
            listItem_active: ResultsSortings.listItem_active,
            paper: ResultsSortings.paper,
            mobile__list: ResultsSortings.mobile__list,
            mobile__list__title: ResultsSortings.mobile__list__title,
            mobile__list__close: ResultsSortings.mobile__list__close,
            mobile__list__item: ResultsSortings.mobile__list__item,
            mobile__list__button: ResultsSortings.mobile__list__button,
            mobile__list__button_inactive: ResultsSortings.mobile__list__button_inactive,
            mobile__closeButton: ResultsSortings.mobile__closeButton,
            switch__component: cn(Switch.wrapper)
        },
        FlightsList: {
            controls__wrapper: ResultsFlightsList.controls__wrapper,
            leg: ResultsFlightsList.leg
        },
        MobilePriceGraph: {
            dates: ResultsMobilePriceGraph.dates
        }
    },
    WeekCalendar: {
        WeekCalendarStyles: {
            dayWrapper: WeekCalendar.dayWrapper
        },
        DayStyles: {
            day: WeekCalendar.day,
            day_best: WeekCalendar.day_best,
            day_selected: WeekCalendar.day_selected,
            day_notAvailable: WeekCalendar.day_notAvailable,
            date: WeekCalendar.date,
            date__dow: WeekCalendar.date__dow,
            date__info: WeekCalendar.date__info,
            price: WeekCalendar.price,
            price__wrapper: WeekCalendar.price__wrapper
        },
        SliderStyles: {
            arrow: WeekCalendarSlider.arrow,
            arrow_prev: WeekCalendarSlider.arrow_prev,
            arrow_next: WeekCalendarSlider.arrow_next
        }
    },
    DesktopFlightRoute: {
        DesktopFlightRouteStyles: {
            flight: DesktopFlightRoute.flight
        },
        SegmentStyles: {
            flightInfo__date: DesktopFlightRouteSegment.flightInfo__date,
            flightInfo__airline: DesktopFlightRouteSegment.flightInfo__airline,
            aircraft: DesktopFlightRouteSegment.aircraft,
            segment: DesktopFlightRouteSegment.segment,
            segment_additional: DesktopFlightRouteSegment.segment_additional,
            totalDuration__decorator: DesktopFlightRouteSegment.totalDuration__decorator,
            flightInfo__nextDay: DesktopFlightRouteSegment.flightInfo__nextDay,
            planeInfo: DesktopFlightRouteSegment.planeInfo
        },
        PointStyles: {
            time: DesktopFlightRoutePoint.time,
            airport: DesktopFlightRoutePoint.airport,
            point_thin: DesktopFlightRoutePoint.point_thin,
            date__container: DesktopFlightRoutePoint.date__container
        },
        RouteArrowStyles: {
            timeInRoute: DesktopFlightRouteArrow.timeInRoute,
            flightNumber: DesktopFlightRouteArrow.flightNumber
        },
        FlightPriceStyles: {
            price: DesktopFlightRoutePrice.price,
            button: DesktopFlightRoutePrice.button
        },
        StopsInfoStyles: {
            stop: DesktopFlightRouteStopsInfo.stop
        }
    },
    DesktopFlight: {
        ModalContent: {
            buttons: DesktopFlightModalContent.buttons,
            fareGroup__button: DesktopFlightModalContent.fareGroup__button,
            fareGroup__button_active: DesktopFlightModalContent.fareGroup__button_active,
            total_time: cn(TransferInfo.transfer, TransferInfo.transfer__layover),
            compareFaresLink: DesktopFlightModalContent.compareFaresLink,
            fareGroup_withoutUpgradeOption: DesktopFlightModalContent.fareGroup_withoutUpgradeOption
        },
        FlightInfoSegment: {
            card: DesktopFlightInfoSegment.card
        },
        Location: {
            time: DesktopFlightLocation.time,
            time_iata: DesktopFlightLocation.time_iata,
            city: DesktopFlightLocation.city,
            date: DesktopFlightLocation.date,
            date_icon: DesktopFlightLocation.date_icon,
            airport: DesktopFlightLocation.airport,
            isMobile: DesktopFlightLocation.isMobile
        },
        SelectedFareGroup: {
            fare: cn(FareGroup.selectedFare),
            button: DesktopFlightSelectedFareGroup.button,
            button__price: DesktopFlightSelectedFareGroup.button__price,
            button__rules: DesktopFlightSelectedFareGroup.button__rules
        },
        DesktopFlightModalHeader: {
            close: DesktopFlightModalHeader.close,
            close__icon: DesktopFlightModalHeader.close__icon
        }
    },
    FareGroup: {
        FareGroup: {
            fareGroup: FareGroup.fareGroup,
            fareGroup_white: FareGroup.fareGroup_white,
            fareGroup_recommended: FareGroup.fareGroup_recommended,
            fareGroup_Business: FareGroup.fareGroup_Business,
            option: FareGroup.option,
            option__title: FareGroup.fareOption__title,
            option__value: FareGroup.option__value,
            option__icon: FareGroup.option__icon,
            option_unavailable: FareGroup.option_unavailable,
            keyFeature: FareGroup.keyFeature,
            button: FareGroup.button,
            info: FareGroup.info,
            upgradeOffer__link: FareGroup.upgradeOffer__link
        },
        FareGroupGrid: {
            header: FareGroupGrid.header,
            option: FareGroupGrid.option,
            paid: FareGroupGrid.paid,
            not_available: FareGroupGrid.notAvailable,
            icon: FareGroupGrid.icon,
            availabilityIcon: FareGroupGrid.availabilityIcon,
            container: FareGroupGrid.container,
            title: FareGroupGrid.title,
            upgradeOffer: FareGroupGrid.upgradeOffer
        },
        FareGroupIcon: {
            container: FareGroupIcon.container
        }
    },
    FlightCard: {
        FlightCard: {
            airline: FlightCard.airline,
            airline__number: FlightCard.airline__number,
            airline__name: FlightCard.airline__name,
            aircraft__name_active: FlightCard.aircraft__name_active
        }
    },
    TransferInfo: {
        TransferInfo: {
            transfer: TransferInfo.transfer,
            transfer__layover: TransferInfo.transfer__layover
        }
    },
    Modal: {
        Modal: {
            modal: Modal.modal
        }
    },
    CompareFares: {
        CompareFares: {
            mobile: CompareFares.mobile,
            selectedFare: CompareFares.selectedFare,
            compareFares: CompareFares.compareFares,
            slider_withoutImages: CompareFares.slider_withoutImages
        }
    },
    FareConditions: {
        FareConditions: {
            paperRoot: FareConditions.paperRoot
        }
    },
    FareLock: {
        FareLock: {
            root: FareLock.root
        },
        DescriptionBlock: {
            root: FareLockDescription.root,
            icon: FareLockDescription.icon,
            title: FareLockDescription.title,
            description: FareLockDescription.description
        },
        SwitchBlock: {
            root: FareLockSwitch.root,
            switch_wrapper: FareLockSwitch.switch_wrapper,
            switch_label: FareLockSwitch.switch_label,
            switch_control: FareLockSwitch.switch_control,
            switch_active: FareLockSwitch.switch_active,
            price_wrapper: FareLockSwitch.price_wrapper,
            price_label: FareLockSwitch.price_label,
            price: FareLockSwitch.price
        }
    },
    Toolbar: {
        Toolbar: {
            toolbar: Toolbar.toolbar,
            button: Toolbar.button,
            backButton: Toolbar.backButton,
            price: Toolbar.price,
            actions: Results.toolbar_actions
        }
    },
    MobileToolbar: {
        MobileToolbar: {
            wrapper: MobileToolbar.wrapper,
            button_back: MobileToolbar.button_back,
            button_continue: MobileToolbar.button_continue
        }
    },
    PriceGraph: {
        LegendsStyles: {
            line: PriceGraphLegends.line,
            line_min: PriceGraphLegends.line_min,
            money: PriceGraphLegends.money
        }
    },
    Amenities: {
        Amenities: {
            amenities__item: Amenities.amenities__item
        }
    },
    Switch: {
        Switch: {
            wrapper: Switch.wrapper,
            active: Switch.active,
            label: Switch.label
        }
    },
    SeatMap: {
        AirplaneStyles: {
            airplane__name: Airplane.airplane__name
        },
        Controls: {
            button: Controls.button,
            controls: Controls.controls
        },
        Passenger: {
            item: Passenger.item,
            item_active: Passenger.item_active,
            item_recommend: Passenger.item_recommend,
            money: Passenger.money,
            select: Passenger.select,
            avatar: Passenger.avatar,
            clear: Passenger.clear
        },
        SeatsInfoStyles: {
            price: SeatsInfo.price,
            price__wrapper: SeatsInfo.price__wrapper,
            image: SeatsInfo.image,
            seat_free: SeatsInfo.seat_free,
            seat_business: SeatsInfo.seat_business,
            title: SeatsInfo.title,
            additionalInfo: SeatsInfo.additionalInfo,
            note: SeatsInfo.note
        },
        SeatStyles: {
            seat: Seat.seat,
            seat_business: Seat.seat_business,
            seat_occupied: Seat.seat_occupied,
            seat_unavailable: Seat.seat_unavailable
        },
        SeatTooltipStyles: {
            container: SeatTooltip.container,
            popper: SeatTooltip.popper,
            tooltip: SeatTooltip.tooltip,
            tooltip_recommend: SeatTooltip.tooltip_recommend,
            tooltip_withInfo: SeatTooltip.tooltip_withInfo
        },
        SeatServiceSlide: {
            single: Seat.single,
            single_cancel: Seat.single_cancel
        }
    },
    SegmentTabs: {
        SegmentTab: {
            checked: SegmentTabs.tab_checked,
            segment: SegmentTabs.tab_segment
        }
    },
    Checkin: {
        Inital: {
            wrapper: CheckinInitial.wrapper,
            form: CheckinInitial.form,
            hint: CheckinInitial.hint
        },
        Passengers: {
            title: CheckinPassengers.title,
            segments: CheckinPassengers.segments,
            toolbar: CheckinPassengers.toolbar,
            toolbar__button: CheckinPassengers.toolbar__button
        },
        PassengersHeader: {
            container: CheckinPassengers.container
        },
        CheckinErrorBoundary: {
            button: CheckinInitial.checkinErrorBoundary__button
        }
    },
    RetrieveBooking: {
        RetrieveBooking: {
            container: cn(RetrieveBooking.container, CheckinInitial.retrieveBooking),
            title: cn(RetrieveBooking.title, CheckinInitial.retrieveBooking__title),
            title__icon: RetrieveBooking.title__icon,
            button: RetrieveBooking.button
        }
    },
    CheckinRules: {
        CheckinRules: {
            rules__button: CheckinRules.rules__button
        }
    },
    Segment: {
        SegmentCommon: {
            wrapper: Segment.wrapper,
            wrapper_business: Segment.wrapper_business,
            header: Segment.header,
            city__weather: Segment.city__weather,
            flightInfo__item: Segment.flightInfo__item,
            flightInfo__title: Segment.flightInfo__title,
            flightInfo__value: Segment.flightInfo__value,
            city__airport: Segment.city__airport,
            city__info: Segment.city__info,
            city__iata: Segment.city__iata,
            city__time: Segment.city__time,
            plane__icon: Segment.plane__icon,
            airline__name: Segment.airline__name,
            footer: Segment.footer,
            footer__item: Segment.footer__item,
            footer__airline: Segment.footer__airline
        }
    },
    MobileSegment: {
        MobileSegment: {
            wrapper: MobileSegment.wrapper,
            info: MobileSegment.info,
            flightInfo: MobileSegment.flightInfo,
            flightInfo__iatas: MobileSegment.flightInfo__iatas,
            flightInfo__weather: MobileSegment.flightInfo__weather,
            flightInfo__dates: MobileSegment.flightInfo__dates,
            flightInfo__cities: MobileSegment.flightInfo__cities,
            flightInfo__time: MobileSegment.flightInfo__time,
            arrivalInfo: MobileSegment.arrivalInfo,
            fareGroup: MobileSegment.fareGroup,
            fareGroup__img: MobileSegment.fareGroup__img,
            fareGroup__info: MobileSegment.fareGroup__info,
            planeIcon: MobileSegment.planeInfo
        }
    },
    SelectedPassenger: {
        SelectedPassenger: {
            wrapper: SelectedPassenger.wrapper,
            passenger: SelectedPassenger.passenger,
            passenger__icon: SelectedPassenger.passengerIcon,
            passenger__name: SelectedPassenger.passengerName,
            passengerInfo: SelectedPassenger.passengerInfo,
            passengerInfo__item: SelectedPassenger.passengerInfo__item,
            passengerInfo__field: SelectedPassenger.passengerInfo__field,
            loyalty__link: SelectedPassenger.loyalty__link
        }
    },
    AddPassenger: {
        AddPassenger: {
            wrapper: AddPassenger.wrapper,
            form__wrapper: AddPassenger.formWrapper,
            form: AddPassenger.form,
            form__input: AddPassenger.form__input,
            addButton: AddPassenger.addButton,
            addButton__icon: AddPassenger.addButtonIcon,
            submitButton: AddPassenger.submitButton,
            passengerIcon: AddPassenger.passengerIcon
        }
    },
    RegisteredPassenger: {
        RegisteredPassenger: {
            wrapper: RegisteredPassenger.wrapper,
            header: RegisteredPassenger.header,
            passenger: RegisteredPassenger.passenger,
            seats: RegisteredPassenger.seats,
            seats__icon: RegisteredPassenger.seats__icon,
            footerActions: RegisteredPassenger.footerActions,
            footerActions__cancel: RegisteredPassenger.footerActions__cancel,
            actions__buttons: RegisteredPassenger.actions__buttons,
            appleWallet: RegisteredPassenger.appleWaller,
            downloadButton: RegisteredPassenger.downloadButton,
            downloadIcon: RegisteredPassenger.downloadIcon,
            actions__barcode: RegisteredPassenger.actions__barcode,
            actions__barcode_disabled: RegisteredPassenger.actions__barcode_disabled,
            services: RegisteredPassenger.services
        },
        SendToEmail: {
            wrapper: RegisteredPassenger.sendToEmail__wrapper,
            text: RegisteredPassenger.sendToEmail__text
        },
        Service: {
            service: RegisteredPassengerService.service,
            service__icon: RegisteredPassengerService.service__icon,
            service__info: RegisteredPassengerService.service__info,
            service__name: RegisteredPassengerService.service__name
        }
    },
    ResendTicketsForm: {
        ResendTicketsForm: {
            wrapper: ResendTicketsForm.wrapper,
            title: ResendTicketsForm.title,
            footer: ResendTicketsForm.footer,
            cancel: ResendTicketsForm.cancel
        }
    },
    StatusMessage: {
        StatusMessage: {
            statusMessage: StatusMessage.wrapper,
            statusMessage_success: StatusMessage.statusMessage_success,
            statusMessage__title: StatusMessage.statusMessage__title,
            statusMessage__text: StatusMessage.statusMessage__text
        }
    },
    MealService: {
        List: {
            item: MealService.list__item,
            item_selected: MealService.list__item_selected
        }
    },
    QuickSearchForm: {
        Summary: {
            trigger__arrow: Summary.trigger__arrow
        }
    },
    PassengerForm: {
        PopupWrapper: {
            cancel: PopupWrapper.cancel,
            confirm: PopupWrapper.confirm
        }
    },
    MobileStepbar: {
        MobileStepbarStyles: {
            expand: MobileStepbar.expand
        }
    },
    PaymentForm: {
        PaymentForm: {
            cards_header: PaymentForm.cardsHeader
        }
    }
};
export default theme;
