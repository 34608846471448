var _a;
import { getLocale } from '@websky/core/src/utils';
import { CardType } from '@websky/core/src/PromoLoader/types';
import i18next from 'i18next';
import { OauthServiceType } from '@websky/graphql';
import { StepsEnum } from '@websky/core/src/types';
var isProd = process.env.NODE_ENV === 'production';
var currentLocale = getLocale();
var companyInfo = {
    multiInsurance: true,
    specialDocumentForInsurance: false,
    facebook: 'https://www.facebook.com/SomonAir/',
    instagram: 'https://instagram.com/somon.air',
    vk: '',
    twitter: 'https://twitter.com/somonairpage',
    baggage: 'https://somonair.com/information?inf=11&group=2',
    meal: '',
    seat: '',
    legal: 'https://somonair.com/information?inf=2&group=1',
    privacyPolicy: 'https://private.sirena-travel.ru/docs/conditions-rus-eng.pdf',
    webskySearchOrder: "https://booking.somonair.com/websky/?lang=".concat(currentLocale, "#/search-order"),
    insurance: '',
    loyaltyProgramRegistrationUrl: '',
    loyaltyProgramAccountUrl: '',
    loyaltyProgramName: '',
    iataCode: 'SZ',
    exarePrivacyPolicyURL: 'https://somonair.com/privacy/policy',
    exareRulesURL: 'https://somonair.com/information?inf=7&group=1',
    baggageAnimalsURL: 'https://somonair.com/information?inf=18&group=3',
    loyalty: {
        links: [
            {
                title: 'Statuses',
                url: ''
            },
            {
                title: 'Earn',
                url: ''
            },
            {
                title: 'Use',
                url: ''
            },
            {
                title: 'FAQ',
                url: ''
            }
        ]
    }
};
export var i18n = {
    ru: {
        BaggagePage: {
            'Transportation of impressions no limitations': 'Какой багаж возьмете с собой в путешествие?'
        },
        Checkout: {
            'Transportation of impressions no limitations': 'Какой багаж возьмете с собой в путешествие?'
        },
        LoginPage: {
            'Email / Phone / Card number': 'Эл. почта'
        },
        Title: {
            companyName: 'Somon Air'
        }
    },
    en: {
        BaggagePage: {
            'Transportation of impressions no limitations': 'What bags are you taking on travel?'
        },
        Checkout: {
            'Transportation of impressions no limitations': 'What bags are you taking on travel?'
        },
        LoginPage: {
            'Email / Phone / Card number': 'Email'
        },
        Title: {
            companyName: 'Somon Air'
        }
    },
    tg: {
        LoginPage: {
            'Email / Phone / Card number': 'почтаи электронӣ'
        },
        Title: {
            companyName: 'Somon Air'
        },
        LocationAutocomplete: {
            'Recent searches': 'Қаблан ҷустуҷӯ доштед'
        },
        Passenger: {
            docExpiryDate: 'Мӯҳлати амал'
        },
        Passengers: {
            '12+ years': 'Аз 12 - сола боло',
            'Up to 2 years': 'То 2 – сола'
        },
        SearchFormDatepicker: {
            Clear: 'Бекор кардан',
            Done: 'Ок'
        },
        Results: {
            'Show price diagram': 'Графики нархҳо'
        },
        QuickSearchForm: {
            'Modify search': 'Иваз кардани ҷустуҷӯ'
        },
        IncludedBaggage: {
            'Price for all flights': 'Нарх барои тамоми парвоз',
            Cancel: 'Бекор кардан'
        },
        AdditionalBaggage: {
            'More bags?': 'Бағоҷи барзиёд доред?',
            'Check-in baggage': 'Бағоҷи барзиёд'
        },
        Contacts: {
            'Your contacts': 'Почтаи электронӣ ва телефон',
            'Contact details are necessary for us to be able to pass on the latest flight information.': 'Маълумоти зерин барои тамос бо Шумо лозим мебошад'
        },
        Checkout: {
            'I accept the': 'Ман',
            'terms of tariff': 'бо шартҳои тарофа',
            'terms of agreement': 'шартнома',
            'processing of my personal data': 'коркарди маълумотҳои шахсӣ розӣ мебошам'
        },
        OrderCard: {
            'The order must be paid today': 'Вақти ҷудошуда барои пардохт имрӯз то соати'
        },
        PaymentTimeLimit: {
            'time to complete booking has expired': 'вақти барои фармоиш ҷудошуда ба итмом расид'
        },
        SeatMap: {
            '90% on the left side': 'офтоб аз тарафи чап',
            '90% on the right side': 'офтоб аз тарафи рост',
            'Pay attention!': 'Таваҷҷӯҳ!',
            'The airline reserves the right to replace the aircraft.': 'Ширкати ҳавопаймоӣ ҳуқуқ дорад ҳавопайморо иваз кунад.',
            'In the event of an aircraft replacement, passengers who have paid for the “seat selection” service will be provided with seats on the plane that are similar in price and comfort, while maintaining the following parameters': 'Дар ҳолати иваз шудани ҳавопаймо, ба мусофироне, ки "Интихоби ҷой"-ро пардохт намудаанд, ҷой дар ҳавопаймо баробар ба нарх ва бароҳатӣ бо нигоҳ доштани шарту шароитҳои зерин, пешниҳод мегардад',
            '- Cost of services;': '— Маблағи хизматрасониҳо;',
            '- Location of the place relative to the window / aisle;': '— Ҷойгиршавии ҷой дар назди тиреза/гузаргоҳ;',
            '- Joint seating, if one was originally selected;': '— Ҷойгиркунии ҳамроҳ, агар чунин аз ибтидо интихоб шуда бошад;',
            '- Placement in the front row of the cabin, if one was originally selected.': '— Ҷойгиркунӣ дар қатори якуми ҳавопаймо, агар чунин аз ибтидо интихоб шуда бошад.'
        }
    }
};
export var promoLoaders = [
    {
        type: CardType.Seats,
        title: 'Comfort on board',
        text: 'We want to make sure your travel experience is as fun and smooth as possible also when travelling as a family. All the way from planning your trip to arriving at the airport and enjoying your time in the air, we pay special attention to your comfort.',
        image: 'https://cdn.websky.aero/content/frontend/images/sz-banners/aircraft_1.jpg'
    },
    {
        type: CardType.Meal,
        title: 'Meal',
        text: 'Every flight is an opportunity to relax and replenish. On our local flights by Russia and flights to CIS, we serve a diverse selection of food, snacks and beverages, tailored to the time and length of your flight.',
        image: 'https://cdn.websky.aero/content/frontend/images/sz-banners/aircraft_1.jpg'
    },
    {
        type: CardType.Aircraft,
        title: 'Fleet',
        text: 'The fleet consists of over 30 aircraft. Our fleet is one the most modern in country, and thanks to it can offer you even better travel comfort on our flights.',
        image: 'https://cdn.websky.aero/content/frontend/images/sz-banners/aircraft_1.jpg'
    },
    {
        type: CardType.WiFi,
        title: 'Wi-Fi Connect',
        text: 'Flying is not a reason to refuse to communicate with family and colleagues. Wi-Fi internet on board is a unique portal to the world of entertainment and information in flight.',
        image: 'https://cdn.websky.aero/content/frontend/images/sz-banners/aircraft_1.jpg'
    }
];
var defaultSteps = (_a = {},
    _a[StepsEnum.Flights] = {
        label: i18next.t('Stepbar:Flights')
    },
    _a[StepsEnum.Passengers] = {
        label: i18next.t('Stepbar:Passengers')
    },
    _a[StepsEnum.Order] = {
        label: i18next.t('Stepbar:Order')
    },
    _a[StepsEnum.Payment] = {
        label: i18next.t('Stepbar:Payment')
    },
    _a);
export var config = {
    global: {
        companyInfo: companyInfo,
        showIsActualizationResultOk: false,
        i18n: i18n,
        findOrderURL: '',
        seatMap: {
            showRecommendedSeats: false
        },
        socialAuthMethods: [
            {
                service: OauthServiceType.Google,
                isAvailable: true
            },
            {
                service: OauthServiceType.Vkontakte,
                isAvailable: true
            },
            {
                service: OauthServiceType.Facebook,
                isAvailable: true
            }
        ],
        authMethods: {
            ffp: false,
            phone: false
        },
        reserveEngines: {
            siteUrl: 'https://somonair.com/'
        }
    },
    Account: {
        engineURL: './booking',
        showUserPhoneInput: false
    },
    Engine: {
        calendarDaysCount: 5,
        searchFormURL: isProd ? './' : null,
        checkInURL: './check-in',
        checkInEnabled: true,
        showAvailableFaresOnly: true,
        promoLoaders: promoLoaders,
        defaultSteps: defaultSteps
    },
    SearchForm: {
        payByMiles: false,
        showPricesInDatepicker: true,
        enableComplexRoute: false,
        selectableCurrencies: [
            // {
            // 	code: 'USD',
            // 	symbol: '$'
            // },
            {
                code: 'EUR',
                symbol: '€'
            }
            // {
            // 	code: 'TJS',
            // 	symbol: 'C.'
            // }
        ]
    }
};
